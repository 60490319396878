import React, { useEffect, useMemo } from "react";
import Header from "../../components/Header/Header";
import { useAuth } from "../../state/Auth";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { History } from "history";
import ScheduleModal from "../../components/ScheduleModal/ScheduleModal";
import { useApp } from "./AppProvider";
import TroubleshootingModal from "../../components/TroubleshootingModal";
import { useStyles } from "./App.style";
import clsx from "clsx";
import { useDevices } from "../../state/Devices";
import Routes from "../../containers/Routes/Routes";

interface IProps {
  history: History;
}

function App(props: IProps) {
  const { history } = props;
  const classes = useStyles();

  // const history = useHistory();
  const location = useLocation();

  const { isLoggedIn } = useAuth();

  const {
    handleSetIsLoading,
  } = useApp();

  useEffect(() => {
    if (!isLoggedIn) {
      console.log("push to login");
      history.push("/auth");
    }
    history.listen(() => {
      window.scrollTo(0, 0);
    });
  }, [isLoggedIn, history]);

  const { loading } = useDevices();

  useEffect(() => {
    handleSetIsLoading({ scope: "devices", value: loading });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const headerColor = useMemo(() => location.pathname === "/" ? "primary" : "secondary", [location.pathname]);

  return (
    <div className={clsx("App", classes.root)}>
      <Header color={headerColor} />

      <Routes />

      <TroubleshootingModal />
      <ScheduleModal />
    </div>
  );
}

export default withRouter(App);
