import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import debounce from "lodash/debounce";
import clsx from "clsx";
import { useStyles } from "./styles";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Button from "@material-ui/core/Button";
import SearchDeviceRow from "../SearchDeviceRow";
import SearchBuildingRow from "../SearchBuildingRow";
import SearcIcon from "../../assets/icons/Search";
import CloseSearch from "../../assets/icons/CloseSearch";
import NoResultIcon from "../../assets/icons/noResult.svg";
import { useTranslation } from "react-i18next";
import { useDevices } from "../../state/Devices";

interface IProps {
}

const SearchBar = (props: IProps) => {
  const classes = useStyles();

  const [focus, setFocus] = useState<boolean>(false);
  const inputElement = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [localSearchText, setLocalSearchText] = useState<string>("");
  const { t } = useTranslation();
  const location = useLocation();

  const { searchText, setSearchText, searchBuildings, searchDevices } =
    useDevices();

  const clearSearch = () => {
    setLocalSearchText("");
    setOpen(false);
    inputElement?.current?.focus();
  };

  useEffect(() => {
    if (open) {
      document.body.setAttribute("style", `overflow: hidden; `);
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  useEffect(() => {
    setOpen(false);
  }, [location, setSearchText]);

  const debounceFunc = debounce((value: string) => {
    setSearchText(value);
  }, 500);

  const handleChange = (e: any) => {
    setLocalSearchText(e.target.value);
    setOpen(true);
    debouncedHandleChange(e.target.value);
  };

  const debouncedHandleChange = (value: string) => debounceFunc(value);

  const showResults = useMemo(() => searchText && open, [searchText, open]);

  return (
    <>
      <div className={classes.inputWrap}>
        <SearcIcon
          styleClass={clsx(classes.lens, focus && classes.activeLens)}
        />
        <input
          value={localSearchText}
          type="text"
          placeholder={t("search.search")}
          ref={inputElement}
          onChange={handleChange}
          onFocus={() => setFocus((prev) => !prev)}
          onBlur={() => setFocus((prev) => !prev)}
        />
        <Button
          className={clsx(
            classes.clear,
            localSearchText && classes.clearActive,
          )}
          onClick={clearSearch}
        >
          <CloseSearch />
        </Button>
      </div>
      {showResults && (
        <div className={classes.searchResultContainer}>
          <div className={classes.searchResult}>
            <div className={classes.searchResultWrap}>
              {searchDevices.length > 0 ? (
                <>
                  <div className={classes.searchResultBox}>
                    {searchDevices.length > 1 && (
                      <Typography className={classes.titleHint}>
                        {t("search.searchText", {
                          devices: searchDevices.length || 0,
                          buildings: searchBuildings.length || 0,
                        })}
                      </Typography>
                    )}

                    <Typography
                      variant="h3" className={classes.title}
                    >
                      {t("pages.devices")}{" "}
                      {searchDevices.length || 0}
                      {searchDevices.length > 10 && (
                        <Link
                          className={classes.button}
                          to={`/search?query=${searchText}`}
                        >
                          <span>{t("button.show_all")}</span>
                        </Link>
                      )}
                    </Typography>

                    <Table>
                      <TableBody>
                        {searchDevices
                          .slice(0, 10)
                          .map((device, index) => (
                            <SearchDeviceRow searchText={searchText} device={device} key={index} />
                          )) || null}
                      </TableBody>
                    </Table>
                    {searchDevices.length > 10 && (
                      <div className={classes.showAllDevices}>
                        <Link to={`/search?query=${searchText}`}>
                          {t("button.show_all")} {searchDevices.length || 0}{" "}
                          {t("common.devices")}
                        </Link>
                      </div>
                    )}
                  </div>

                  {searchDevices.length > 1 && (
                    <div className={classes.searchResultBox}>
                      <Typography variant="h3" className={classes.title}>
                        {`${t("pages.buildings")} ${searchBuildings.length}`}
                      </Typography>
                      <Table>
                        <TableBody>
                          {searchBuildings?.map((building, index) => (
                            <SearchBuildingRow
                              building={building}
                              key={index}
                              searchText={searchText}
                            />
                          )) || null}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </>
              ) : (
                <div className={classes.noResults}>
                  <img src={NoResultIcon} alt="" />
                  {t("search.no_results")}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBar;
