import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "./locales/en.json";
import translationFi from "./locales/fi.json";
import translationEe from "./locales/ee.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next).init({
  resources: {
    en: {
      translations: translationEn,
    },
    ee: {
      translations: translationEe,
    },
    fi: {
      translations: translationFi,
    },
  },
  compatibilityJSON: "v1",
  fallbackLng: "en",
  debug: false,
  lng: localStorage.getItem("themo-lang") || "en",
  ns: ["translations"],
  defaultNS: "translations",
  react: {
    useSuspense: true,
  },
}).catch(er => console.log("i18n init error", er));

export default i18n;