import { useCallback, useEffect, useMemo, useState } from "react";
import { days } from "../constants/days";
import { pointType, scheduleInterface } from "../types/Schedule";
import {
  apiGetPredefinedTemperatureSchedules,
  apiGetTemperatureSchedules,
  apiPostTemperatureSchedule, apiSwitchTemperatureSchedule, apiUpdateTemperatureSchedule, apiVers,
} from "../api/schedule";
import { deviceType } from "../types/Devices";
import { useAuth } from "./Auth";
import { toast } from "react-toastify";
import { useApp } from "../views/App/AppProvider";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { Endpoints } from "../api/endpoints";
import { useSWRConfig } from "swr";
import { useDevices } from "./Devices";

export const useSchedule = () => {
  const {
    scheduleSwitchMode, setScheduleSwitchMode,
    openScheduleModal, setOpenScheduleModal,
    scheduleName, setScheduleName,
    setSelectedDevices,
  } = useApp();

  const { getDevicesSchedules } = useDevices();

  const { mutate } = useSWRConfig();

  const [name, setName] = useState<string>(scheduleName);
  const [nameError, setNameError] = useState<boolean>(false);

  const [devices, setDevices] = useState<deviceType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);

  const [dragEl, setDragEl] = useState<{ day: number, hour: number, value: number, count: number, position: number, newHour?: number }>();

  const [open, setOpen] = useState<{ day: number, hour: number, value?: number, count?: number }>();

  const [copyDay, setCopyDay] = useState<number>();

  const [pastedDays, setPastedDays] = useState<number[]>([]);

  // const [points, setPoints] = useState<pointType[]>([...defaultSetpoints]);
  const [points, setPoints] = useState<pointType[]>([]);

  const [wasChange, setWasChange] = useState<boolean>(false);

  const [localPoints, setLocalPoints] = useState<pointType[]>([]);

  const [selectedPredefinedScheduleName, setSelectedPredefinedScheduleName] = useState<string>("");
  const [predefinedSchedules, setPredefinedSchedules] = useState<scheduleInterface[]>([]);

  const { token } = useAuth();
  const [wasTemperatureSchedules, setWasTemperatureSchedules] = useState<boolean>(false);

  useEffect(() => {
    if (!(devices && devices.length > 0)) {
      setWasTemperatureSchedules(false);
    }
  }, [devices.length]);

  useEffect(() => {
    setName(scheduleName);
  }, [scheduleName]);

  const reset = useCallback(() => {
    setName("");
    setNameError(false);
    setScheduleName("");
    setScheduleSwitchMode(false);
    setWasChange(false);
    setPoints([]);
    setLocalPoints([]);
    setPastedDays([]);
    setCopyDay(undefined);
    setOpen(undefined);
    setDragEl(undefined);
    setSelectedPredefinedScheduleName("");
    setWasTemperatureSchedules(false);
    setDevices([]);
    setOpenScheduleModal(false);
    setPredefinedSchedules([]);
    setOpenConfirmationDialog(false);
    setSelectedDevices([]);
  }, [setScheduleName, setOpenScheduleModal, setSelectedDevices, setScheduleSwitchMode])

  const refetchDeviceSchedules = useCallback(() => {
    getDevicesSchedules()
    if (devices && devices.length === 1) {
      console.log("refetchDeviceSchedules...");
      const deviceId = devices[0].ID;
      const urlSchedules = `${Endpoints.devices}/${deviceId}/schedules/temperature?api-version=${apiVers}`;
      mutate([urlSchedules, token]).then((res) => {
        console.log("refetchDeviceSchedules success", res);
      }).catch((err) => {
        console.log("refetchDeviceSchedules error");
      });
    }
  }, [devices, token, getDevicesSchedules]);

  const getPredefinedSchedules = useCallback(async (deviceId: string | number) => {
    let schName: string = ""
    let schPoints: pointType[] = []
    await apiGetPredefinedTemperatureSchedules({
      deviceId,
      token,
    }).then((resp) => {
      const schedules = resp.data;
      if (schedules && schedules.length > 0) {
        setPredefinedSchedules(cloneDeep(schedules));
        toast("Getting predefined temperature schedule. You are able to edit and save it as your own.", {
          type: "info",
        });
        const activeSchedule = schedules.find((sch: any) => sch.Active);
        schName = activeSchedule ? activeSchedule.Name : schedules[0].Name
        schPoints = activeSchedule ? activeSchedule.Setpoints : schedules[0].Setpoints;
      } else {
        toast("Unavailable predefined temperature schedule", {
          type: "warning",
        });
      }
    });
    return {
      schName, schPoints,
    }
  }, [token, setPredefinedSchedules])

  const handleGetTemperatureSchedules = useCallback(async () => {
    if (!wasTemperatureSchedules && devices && devices.length > 0) {
      let _devices = devices;
      let _points: pointType[] = [];

      setIsLoading(true);
      setWasChange(false);

      if (scheduleName) {
        for (const device of devices) {
          let index = devices.indexOf(device);
          await apiGetTemperatureSchedules({
            deviceId: device.ID,
            token,
            scheduleName: scheduleName,
          }).then((resp) => {
            let schedules = resp.data;
            if (schedules && schedules.length > 0) {
              const activeSchedule = schedules.find((sch: any) => sch.Active);
              if (_points.length === 0) {
                _points = activeSchedule ? activeSchedule.Setpoints : schedules[0].Setpoints;
              }
              _devices[index].schedule = activeSchedule ? activeSchedule : schedules[0];
            } else {
              _devices[index].schedule = undefined;
            }
            if (index > 0) {
              // setWasChange(true) - if the other devices' schedules are different
              setWasChange(true);
            }
          });
        }
      }

      if (_points.length === 0 || (predefinedSchedules.length === 0 && !scheduleSwitchMode)) {
        const {schName, schPoints} = await getPredefinedSchedules(devices[0].ID);
        if (schName && schPoints && _points.length === 0) {
          setSelectedPredefinedScheduleName(schName)
          _points = [...schPoints]
        }
      }
      setIsLoading(false);
      setWasTemperatureSchedules(true);
      setPoints(uniq(_points));
      setDevices(_devices);
    }
  }, [wasTemperatureSchedules, devices, scheduleName, predefinedSchedules.length, scheduleSwitchMode, token, getPredefinedSchedules]);

  const postTemperatureSchedule = useCallback(async () => {
    if (isLoading) {
      return;
    }
    if (!name) {
      setNameError(true);
      return;
    }
    setNameError(false);

    let _points: pointType[] = [];
    setPoints(prevState => {
      _points = [...prevState];
      return prevState;
    })
    _points.forEach((p) => delete p.Count);
    _points = uniqWith(_points, isEqual);


    if (devices && devices.length > 0) {
      setIsLoading(true);
      let isOk = true;
      await Promise.all(
        devices.map(async (device, index) => {
          if (device.schedule) {
            if (scheduleSwitchMode) {
              // switch to the current schedule if it isn't already Active for this device
              if (!device.schedule.Active) {
                await apiSwitchTemperatureSchedule({
                  deviceId: device.ID,
                  scheduleName: name,
                  token,
                })
              }
            } else {
              // update the current schedule
              await apiUpdateTemperatureSchedule({
                deviceId: device.ID,
                token,
                scheduleName: name,
                schedule: {
                  ...device.schedule,
                  Setpoints: _points,
                },
              })
                .then((resp) => console.log("apiUpdateTemperatureSchedule deviceId", device.ID))
                .catch(er => {
                  isOk = false;
                  toast(`Update schedule error! (device: ${device.ID}). Check the console for more details.`, {
                    type: "error",
                  });
                  console.log("update schedule error", er);
                });
            }

          } else {
            // create a new schedule
            await apiPostTemperatureSchedule({
              deviceId: device.ID,
              token,
              scheduleName: name,
              Setpoints: _points,
            }).then((resp) => {
              console.log("postTemperatureSchedule deviceId", device.ID, resp.data);
            })
              .catch(er => {
                isOk = false;
                if (er.response?.data?.Message) {
                  if (er.response?.data?.Message === "Schedule with defined name already exists!") {
                    setNameError(true);
                  }
                  toast(er.response?.data?.Message, {
                    type: "error",
                  });
                } else {
                  toast(`Create schedule error! (device: ${device.ID}). Check the console for more details.`, {
                    type: "error",
                  });
                  console.log("create new schedule error", er);
                }
              });
          }
        }),
      );
      if (isOk) {
        refetchDeviceSchedules();
        reset();
        toast("Success", {
          type: "success",
        });
      } else {
        if (scheduleSwitchMode) {
          if (devices.length > 1) {
            refetchDeviceSchedules();
          }
          reset();
        }
      }
      setIsLoading(false);
    }
  }, [isLoading, name, devices, scheduleSwitchMode, token, refetchDeviceSchedules, reset]);

  useEffect(() => {
    if (openScheduleModal) {
      setNameError(false);
      setOpen(undefined);
      setCopyDay(undefined);
      handleGetTemperatureSchedules().then(() => {
        if (scheduleSwitchMode) {
          // proceed directly to save
          postTemperatureSchedule()
        }
      });
    }
  }, [openScheduleModal, devices.length]);

  useEffect(() => {
    setLocalPoints([...points]);
  }, [points]);

  const handleChangePoints = useCallback((
      {
        day,
        hour,
        count = 1,
        value,
        prevHour,
        prevCount = 1,
      }: { day: number, hour: number, count?: number, value: number, prevHour: number, prevCount?: number },
    ) => {

      let _points = [...points];

      let h: number;
      let index = prevCount;

      // clear prev values
      while (index > 0) {
        h = prevHour + index - 1;
        let pointIndex = _points.findIndex(el => el.Hour === h && el.Day === day);
        if (pointIndex !== -1) {
          _points[pointIndex].Value = 0;
          _points[pointIndex].Count = undefined;
        }
        index = index - 1;
      }

      // set new values
      index = count;
      while (index > 0) {
        h = hour + index - 1;
        let pointIndex = _points.findIndex(el => el.Hour === h && el.Day === day);
        if (pointIndex !== -1) {
          _points[pointIndex] = {
            Value: value,
            Day: day,
            Hour: h,
          };
        } else {
          _points.push({
            Value: value,
            Day: day,
            Hour: h,
          });
        }
        index = index - 1;
      }
      setPoints([..._points]);
      setOpen(undefined);
      setWasChange(true);
    },
    [points, setPoints]);

  const hours = useMemo(
    () => [...Array(24).keys()],
    [],
  );

  const schedules = useMemo(
    () => days.map((day, key) => {
      let dayPoints = localPoints.filter(point => point.Day === key);
      let arr = hours.map((el, arrKey) => {
        let point = dayPoints.find(point => point.Hour === arrKey);
        return point;
      });

      arr = arr.map((el, arrKey) => {
        if (el) {
          if (el.Count === -1) {
            return el;
          }
          if (el.Value) {
            let search = true;
            let count = 1;
            while (arrKey + count < arr.length && search) {
              if (el.Value === arr[arrKey + count]?.Value) {
                arr[arrKey + count]!.Count = -1;
                count = count + 1;
              } else {
                search = false;
              }
            }
            return { ...el, Count: count };
          } else {
            return undefined;
          }
        } else return undefined;
      });

      return arr;
    }),
    [hours, localPoints],
  );

  const addToPastedDays = useCallback(
    (day: number) => {
      setPastedDays((pastedDays) => [...new Set([...pastedDays, day])]);
    },
    [],
  );

  const completeCopy = useCallback(
    () => {
      setPoints([...localPoints]);
      setCopyDay(undefined);
      setPastedDays([]);
      if (pastedDays.length > 0) {
        setWasChange(true);
      }
    },
    [localPoints, pastedDays],
  );

  const cancelCopy = useCallback(
    () => {
      setLocalPoints([...points]);
      setPastedDays([]);
      setCopyDay(undefined);
    }, [points],
  );

  useEffect(() => {
    let _points = [...localPoints];
    if (copyDay !== undefined && pastedDays && pastedDays.length > 0) {
      const copyDayPoints = _points.filter(point => point.Day === copyDay);

      pastedDays.forEach(day => {
        _points = _points.filter(point => point.Day !== day);
        _points.push(...copyDayPoints.map((point) => ({ ...point, Day: day })));
      });
      setLocalPoints([..._points]);
    }
  }, [pastedDays]);

  useEffect(() => {
    if (selectedPredefinedScheduleName) {
      // we need a deepCopy here in order not to alterate the original predefinedSchedules array
      const _predefinedSchedules = cloneDeep(predefinedSchedules)
      const schedule = _predefinedSchedules.find((el) => el.Name === selectedPredefinedScheduleName);
      if (schedule) {
        const _points = uniq(schedule.Setpoints)
        setPoints([..._points]);
        setWasChange(true);
      }

    }
  }, [selectedPredefinedScheduleName]);

  return {
    nameError,
    loading: isLoading,
    hours,
    devices, setDevices,
    dragEl, setDragEl,
    open, setOpen,
    points, setPoints,
    schedules,
    handleChangePoints,
    copyDay, setCopyDay,
    pastedDays, setPastedDays, addToPastedDays,
    completeCopy, cancelCopy,
    postTemperatureSchedule,
    allowSubmit: wasChange && !isEmpty(points),
    openConfirmationDialog,
    setOpenConfirmationDialog,
    predefinedSchedules,
    selectedPredefinedScheduleName, setSelectedPredefinedScheduleName,
    name, setName,
    reset,
  };

};