import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      borderBottom: "1px solid #D9DEE3",
      "&:last-of-type": {
        borderBottom: "none",
      },
    },
    col: {
      padding: "18px 56px 24px 0",
      fontFamily: "Roboto",
      fontSize: "16px",
      lineHeight: "19px",
      border: "none",
      "&:last-of-type": {
        padding: "0",
        width: "360px",
      },
      "& svg": {
        margin: "0 10px 0 0",
      },
    },
    deviceName: {
      width: "244px",
      "& span": {
        fontWeight: 700,
      },
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#B5BABF",
      margin: "0 0 10px 0",
    },
    link: {
      width: "24px",
      display: "block",
      padding: "16px 0 16px 0",
      marginLeft: "auto",
    },
  }),
);
