import React from "react";
import { useStyles } from "./styles";
import ChangeLang from "../ChangeLang";
import Logo from "../../assets/icons/themoLogo";

const LoginHeader = () => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <Logo styleClass={classes.logo} fill="#fff" />
      <ChangeLang color="white" />
    </header>
  );
};

export default LoginHeader;
