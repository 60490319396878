import { useLocation } from "react-router-dom";
import { mutateData } from "../api/api";

export const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export const formatHour = (value: number) => {
  if (value < 10) {
    return "0" + value + ":00";
  } else {
    return "" + value + ":00";
  }
};

export const getHour = (value: string) => Number(value.split(":")[0]) || 0;

export const fetcher = ([query, token, type = "get", data = {}]: [query: string, token: string, type: "get" | "post", data: Record<any, any>]) => {
  // query: string, token: string, type: "get" | "post" = "get", data = {}
  return mutateData(type, query, data, {
    "Content-Type": "application/json",
    "client-id": "ThemoPortal",
    Authorization: `Bearer ${token}`,
  }).then(res => res.data);
};

export const validateEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const limit1Decimals = (nr: number) => {
  const t = nr.toString();
  return (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 2)) : t;
};

export const limit2Decimals = (nr: number) => {
  const t = nr.toString();
  return (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
};

export const limit3Decimals = (nr: number) => {
  const t = nr.toString();
  return (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 4)) : t;
};

export const boldText = (name: string, searchText: string) => {
  if (name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())) {
    const ind = name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase());
    const length = searchText.length;

    const word = name.substring(ind, ind + length);

    return name.replace(
      `${word}`,
      `<span>${word}</span>`,
    );
  }
  return name;
};

export const groupAverage = (arr: number[] = [], n: number = 1) => {
  const res = [];
  for (let i = 0; i < arr.length;) {
    let sum = 0;
    for (let j = 0; j < n; j++) {
      sum += +arr[i++] || 0;
    }
    res.push(sum / n);
  }
  return res;
};