import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginBox: {
      display: "flex",
      alignItems: "center",
      flex: "1 0 auto",
    },

    loginCard: {
      display: "flex",
      flexDirection: "column",
      width: "500px",
      margin: "0 auto",
      padding: "102px 106px 98px",
      background: "#fff",
      boxShadow: "0px 40px 80px -30px rgba(22, 4, 87, 0.4)",
      borderRadius: "132px",
    },

    title: {
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: "30px",
      display: "flex",
      color: "#414042",
      margin: "0 0 26px 0",
    },

    inputWrap: {
      position: "relative",
      width: "100%",
      margin: "0 0 16px 0",
    },

    input: {
      width: "100%",
      border: "1px solid #D9DEE3",
      borderRadius: "8px",
      padding: "16px 23px",
      fontSize: "14px",
      lineHeight: "16px",
      transition: "0.2s",
      "&:hover, &:focus": {
        border: "1px solid #7C64AC",
      },
    },

    inputErrorImg: {
      display: "none",
      position: "absolute",
      top: "13px",
      right: "13px",
      width: "24px",
      height: "24px",
    },

    errorMsg: {
      display: "none",
      fontFamily: "Roboto",
      fontSize: "12px",
      lineHeight: "14px",
      color: "#F7204F",
      margin: "5px 0 0 0",
      padding: "0 23px",
    },

    inputError: {
      border: "1px solid #F7204F",
      "& img": {
        display: "block",
      },
    },

    button: {
      "&.MuiButton-root": {
        background: Colors.brand,
        boxShadow:
          "0px 16px 13px -15px rgba(83, 75, 158, 0.3), 0px 8px 22px -8px rgba(92, 79, 161, 0.45)",
        borderRadius: "20px",
        color: "#fff",
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "16px",
        textAlign: "center",
        textTransform: "none",
        padding: "12px 68px",
        margin: "16px auto 28px",
        transition: "0.2s",
        border: "none",
        "&:hover": {
          background: "#3B3E96",
          boxShadow:
            "0px 16px 9px -15px rgba(83, 75, 158, 0.4), 0px 8px 16px -8px rgba(92, 79, 161, 0.5)",
        },
        "&:disabled": {
          background: "#D9DEE3",
          color: "#85868B",
          boxShadow: "none",
        },
      },
    },

    hint: {
      fontFamily: "Roboto",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#85868B",
      textAlign: "center",
      "& a": {
        color: Colors.brand,
        textDecoration: "none",
        margin: "0 0 0 3px",
      },
    },
  })
);
