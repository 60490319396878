import { Box, BoxProps, CircularProgress } from "@material-ui/core";

interface Props extends BoxProps {
  size?: number;
  color?: string;
}

export default function Loader({ size, color, ...rest }: Props) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" padding="6px" {...rest}>
      <CircularProgress size={size ?? 30} style={{ color: color }}/>
    </Box>
  );
}