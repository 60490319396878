import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inputWrap: {
      position: "relative",
      "& input": {
        width: "392px",
        background: "#F6F8FA",
        borderRadius: "8px",
        padding: "12px 48px",
        fontFamily: "Roboto",
        fontSize: "14px",
        lineHeight: "16px",
        color: "#414042",
      },
    },
    lens: {
      position: "absolute",
      top: "50%",
      left: "16px",
      transform: "translate(0, -50%)",
      stroke: "#D9DEE3",
      transition: "0.2s",
    },
    activeLens: {
      stroke: "#7C64AC",
    },
    clear: {
      position: "absolute",
      top: "0",
      right: "0",
      padding: "8px 16px",
      minWidth: "initial",
      opacity: "0",
      visibility: "hidden",
      transition: "0.2s",
      "&:hover": {
        background: "transparent",
        "& .MuiButton-label svg": {
          stroke: "#7C64AC",
        },
      },
      "& .MuiButton-label svg": {
        width: "24px",
        height: "24px",
        stroke: "#D9DEE3",
        transition: "0.2s",
      },
      "& .MuiTouchRipple-root": {
        display: "none",
      },
    },
    clearActive: {
      opacity: "1",
      visibility: "visible",
    },

    searchResultContainer: {
      position: "fixed",
      top: "73px",
      left: "0",
      width: "100%",
      height: "calc(100vh - 73px)",
      background: "rgba(0,0,0,0.85)",
    },
    searchResult: {
      background: "#fff",
      maxHeight: "calc(100vh - 73px)",
      overflow: "auto",
    },
    searchResultWrap: {
      width: "1200px",
      margin: "0 auto",
      padding: "28px 0 0 0",
      [theme.breakpoints.down("md")]: {
        padding: "28px 15px 0",
      },
    },
    searchResultBox: {
      padding: "0 0 38px 0",
      "&:last-of-type": {
        padding: "0",
      },
    },
    titleHint: {
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#B5BABF",
      margin: "0 0 21px 0",
    },
    title: {
      display: "flex",
      alignItems: "center",
      margin: "0 0 17px 0",
      fontSize: "20px",
    },
    button: {
      background: "#F6F8FA",
      borderRadius: "16px",
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      padding: "8px 16px",
      margin: "0 0 0 14px",
      color: Colors.brand,
      "& span": {
        fontWeight: "500",
      },
    },
    showAllDevices: {
      borderTop: "1px solid #D9DEE3",
      padding: "19px 0 0 0",

      "& a": {
        display: "block",
        fontFamily: "Roboto",
        fontSize: "14px",
        lineHeight: "16px",
        fontWeight: "500",
        color: Colors.brand,
        textTransform: "initial",
        padding: "16px 0",
        "&:hover": {
          background: "transparent",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
      },
    },

    noResults: {
      display: "flex",
      alignItems: "center",
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#B5BABF",
      padding: "0 0 25px",
      "& img": {
        margin: "0 16px 0 0",
      },
    },
  })
);
