import React, { useCallback, useMemo } from "react";
import { useStyles } from "./styles";
import Chip from "@material-ui/core/Chip";
import ChipDeleteIcon from "../../assets/icons/ChipDelete";
import { deviceType } from "../../types/Devices";
import { useApp } from "../../views/App/AppProvider";

interface IProps {
  device?: deviceType;
}

const CustomChip = (props: IProps) => {
  const classes = useStyles();

  const { setSelectedDevices, selectedDevices } = useApp();

  const {
    device,
  } = props;

  const handleDelete = useCallback(
    () => {
      console.info("You clicked the delete icon.");
      if (selectedDevices.length > 1) {
        setSelectedDevices(
          selectedDevices.filter(dev => dev.ID !== device?.ID),
        );
      }
    },
    [selectedDevices, device?.ID, setSelectedDevices],
  );

  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  const title = useMemo(() => {
    if (device && device.DeviceName.length > 24) {
      const nameAndBuilding = device.DeviceName.split("-");
      return nameAndBuilding.length > 1
        ? nameAndBuilding[1]
        : nameAndBuilding[0];
    } else {
      return device?.DeviceName || "";
    }
  }, [device]);

  return (
    <Chip
      label={title}
      title={device?.DeviceName || ""}
      onClick={handleClick}
      onDelete={handleDelete}
      className={classes.chip}
      deleteIcon={
        <div>
          <ChipDeleteIcon />
        </div>
      }
    />
  );
};

export default CustomChip;
