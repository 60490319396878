import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: 472,
      position: "relative",
      paddingBottom: 26,
    },
    daysContainer: {
      width: "56px",
      paddingTop: 24,
    },
    rowsContainer: {
      width: "1024px",
      borderBottom: "1px solid #85868B",
      // borderLeft: "1px solid #D9DEE3",
      backgroundSize: "42.61px 100%",
      backgroundRepeat: "repeat-x",
      backgroundImage: "linear-gradient(to right, #D9DEE3 1px, transparent 1px)",
      backgroundPosition: 0,
      padding: "8px 0",
    },
    hoursContainer: {
      position: "absolute",
      display: "flex",
      width: "calc(100% + 16px)",
      justifyContent: "space-between",
      bottom: 0,
      paddingLeft: 40,
    },
  }),
);
