import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4449 4.60886C12.0183 3.89308 10.9817 3.89308 10.5551 4.60886L4.16118 15.3369C3.72418 16.0701 4.2525 17.0001 5.10608 17.0001H17.8939C18.7475 17.0001 19.2758 16.0701 18.8388 15.3369L12.4449 4.60886ZM11.4141 5.12082C11.4529 5.05575 11.5471 5.05575 11.5859 5.12082L17.9798 15.8489C18.0196 15.9155 17.9715 16.0001 17.8939 16.0001H5.10608C5.02849 16.0001 4.98045 15.9155 5.02018 15.8489L11.4141 5.12082ZM10.8269 8.48621C10.8122 8.10432 11.1178 7.78672 11.5 7.78672C11.8822 7.78672 12.1878 8.10432 12.1731 8.48621L12.0192 12.4871C12.0085 12.7661 11.7792 12.9867 11.5 12.9867C11.2208 12.9867 10.9915 12.7661 10.9808 12.4871L10.8269 8.48621ZM12.2499 14.4761C12.2499 14.8903 11.9141 15.2261 11.4999 15.2261C11.0857 15.2261 10.7499 14.8903 10.7499 14.4761C10.7499 14.0619 11.0857 13.7261 11.4999 13.7261C11.9141 13.7261 12.2499 14.0619 12.2499 14.4761Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Icon;
