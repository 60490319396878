import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      background: "linear-gradient(270deg, #5C4FA1 0%, #7C64AC 100%);",
      minHeight: "100vh",
      fontFamily: "Roboto",
    },
    wrap: {
      display: "flex",
      flexDirection: "column",
      width: "1152px",
      margin: "0 auto",
      padding: "63px 0 0 0",
      flex: "1 0 auto",
    },
  })
);
