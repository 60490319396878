import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.styleClass}
    >
      <path
        d="M12 1C5.93263 1 1 5.93263 1 12C1 18.0674 5.93263 23 12 23C18.0674 23 23 18.0674 23 12C23 5.93263 18.0674 1 12 1ZM12 5.81684C13.5053 5.81684 14.7326 7.04421 14.7326 8.54947C14.7326 10.0547 13.5053 11.2821 12 11.2821C10.4947 11.2821 9.26737 10.0547 9.26737 8.54947C9.26737 7.04421 10.4947 5.81684 12 5.81684ZM16.4926 17.0021C16.4926 17.2568 16.2842 17.4884 16.0063 17.4884H7.99368C7.73895 17.4884 7.50737 17.28 7.50737 17.0021V16.6779C7.50737 14.2 9.52211 12.1853 12 12.1853C14.4779 12.1853 16.4926 14.2 16.4926 16.6779V17.0021Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Icon;
