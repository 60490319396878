import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import BigCircle from "../../assets/icons/bigCircle.svg";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      background: "linear-gradient(270deg, #5C4FA1 0%, #7C64AC 100%);",
      minHeight: "100vh",
      fontFamily: "Roboto",
    },

    loginLoading: {
      backgroundImage: `url(${BigCircle})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flex: "1 0 auto",
    },
    logo: {
      width: "120px",
      height: "32px",
      margin: "0 0 18px 0",
    },
    title: {
      fontSize: "18px",
      lineHeight: "32px",
      color: " #FFFFFF",
      margin: "0 0 50px 0",
    },
    loadingCircle: {
      animation: "$fadeIn 1s linear infinite",
    },
    "@keyframes fadeIn": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  })
);
