import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SWRConfig } from "swr";
// import { createBrowserHistory } from "history";
import "./index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./style/theme";
import App from "./views/App/App";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./views/Login/Login";
import { SWROptions } from "./api/api";
import { AuthProvider } from "./state/Auth";
import { AppProvider } from "./views/App/AppProvider";
import { StylesProvider } from "@material-ui/core/styles";
import { DevicesProvider } from "./state/Devices";
import { LangProvider } from "./state/Lang";
// const hist = createBrowserHistory();
// import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
// import { reactPlugin } from "./AppInsights";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/*<AppInsightsContext.Provider value={reactPlugin}>*/}
        <ThemeProvider theme={theme} key={"themo-theme"}>
          <StylesProvider injectFirst={true}>
            <I18nextProvider i18n={i18n}>
              <SWRConfig value={SWROptions}>
                <LangProvider>
                  <AuthProvider>
                    <AppProvider>
                      <DevicesProvider>
                        <CssBaseline key={"themo-theme"} />
                        {/*<Router history={hist}>*/}
                        <Switch>
                          <Route path={"/auth"} component={Login} />
                          <Route path={"/"} component={App} />
                        </Switch>
                        {/*</Router>*/}
                        <ToastContainer
                          position={"bottom-right"}
                          autoClose={2500}
                          hideProgressBar
                          closeOnClick
                          rtl={false}
                          pauseOnHover
                        />
                      </DevicesProvider>
                    </AppProvider>
                  </AuthProvider>
                </LangProvider>
              </SWRConfig>
            </I18nextProvider>
          </StylesProvider>
        </ThemeProvider>
      {/*</AppInsightsContext.Provider>*/}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
