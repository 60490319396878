import { schedulePointType } from "../types/Schedule";

export const getMinHour = (schedules: schedulePointType[], schedule: schedulePointType) => {
  const schedulesCopy = [...schedules];
  const findPrev = schedulesCopy.reverse().find(sch => sch && sch.Count !== -1 && sch.Hour < schedule!.Hour);
  return schedule!.Hour === 0 ? 0 :
    findPrev ? (findPrev.Hour + (findPrev.Count || 1)) : 0;
};

export const getMaxHour = (schedules: schedulePointType[], schedule: schedulePointType) => {
  const findNext = schedules.find(sch => sch && sch.Count !== -1 && sch.Hour > schedule!.Hour);
  return findNext ? findNext.Hour : undefined;
};