import React, { useState } from "react";
import clsx from "clsx";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import InfoIcon from "../../assets/icons/Info";

const Tooltip = ({text}: {text?: string}) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div
        className={clsx(classes.button, open && classes.open)}
        aria-describedby={id}
        onClick={handleClick}
      >
        <InfoIcon />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography variant="body2">
          {text || "This is the minimum temperature which will be kept by the thermostat when it is turеned off. When internet connection is lost longer than 24h then the Frost Protection Temperature will be used to prevent damage to your floor."}
        </Typography>
      </Popover>
    </div>
  );
};

export default Tooltip;
