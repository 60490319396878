import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TroubleshootingMsg from "../TroubleshootingMsg";
import { Typography } from "@material-ui/core";
import WifiIcon from "../../assets/icons/Wifi";
import ArrowIcon from "../../assets/icons/arrow.svg";
import { useTranslation } from "react-i18next";
import { deviceType } from "../../types/Devices";
import { useApp } from "../../views/App/AppProvider";
import xss from "xss";
import { boldText } from "../../utils/Common";

interface IProps {
  device: deviceType;
  searchText: string;
}

const SearchDeviceRow = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { device, searchText } = props;

  const isDevicePriceSwitch = useMemo(() => device.State?.DeviceParameters?.IsPriceSwitch, [device.State?.DeviceParameters?.IsPriceSwitch])

  const {
    openTroubleshootingModal,
    setOpenTroubleshootingModal,
    setTroubleshootingDevice,
  } = useApp();

  const nameAndBuilding = useMemo(() => device.DeviceName.split("-"), [device.DeviceName]);

  const checkStatus = useCallback((value: any) => {
    if (value === 0) {
      return (
        <Typography
          component="span"
          className={classes.status}
          style={{ color: "#85868B" }}
        >
          {t("common.off")}
        </Typography>
      );
    }
    return (
      <Typography
        component="span"
        className={classes.status}
        style={{ color: "#6BC38F" }}
      >
        {t("common.on")}
      </Typography>
    );
  }, [t, classes.status]);

  const checkMode = useCallback((value: any) => {
    if (value === 'Off') {
      return (
        <Typography
        >
          {isDevicePriceSwitch ? t("mode.AlwaysOff") : t("mode.Off")}
        </Typography>
      );
    }
    if (value === 'SLS') {
      return (
        <Typography
        >
          {isDevicePriceSwitch ? t("mode.PriceSwitch") : t("mode.SLS")}
        </Typography>
      );
    }
    return (
      <Typography
      >
        {isDevicePriceSwitch ? t("mode.AlwaysOn") : t("mode.Manual")}
      </Typography>
    );
  }, [t, isDevicePriceSwitch]);

  const handleOpenTroubleshooting = useCallback(() => {
    setOpenTroubleshootingModal(!openTroubleshootingModal);
    setTroubleshootingDevice(device);
  }, [openTroubleshootingModal, device, setOpenTroubleshootingModal, setTroubleshootingDevice]);


  const deviceName = useMemo(() => {
    let name = nameAndBuilding.length > 1 ? nameAndBuilding[1] : nameAndBuilding[0];

    name = boldText(name, searchText)

    return xss(name);

  }, [nameAndBuilding, searchText]);

  const buildingName = useMemo(() => {
    let name = nameAndBuilding.length > 1
      ? nameAndBuilding[0]
      : device.EnvironmentName;

    name = boldText(name, searchText)

    return xss(name);

  }, [nameAndBuilding, device.EnvironmentName, searchText]);

  return (
    <>
      <TableRow className={classes.row}>
        <TableCell className={classes.col}>
          <div className={classes.deviceName}>
            <WifiIcon
              fill={device?.State?.Online === true ? "#6BC38F" : "#D9DEE3"}
            />
            <Typography
              className={classes.customTitle}
              dangerouslySetInnerHTML={{ __html: deviceName }}
            >
            </Typography>
          </div>
        </TableCell>
        <TableCell className={classes.col}>
          <Typography className={classes.title}>
            {t("table_options.building")}
          </Typography>
          <Typography
            className={classes.customTitle}
            dangerouslySetInnerHTML={{ __html: buildingName }}
          >
          </Typography>
        </TableCell>
        <TableCell className={classes.col}>
          <Typography className={classes.title}>
            {t("table_options.t")}
          </Typography>
          {device.State?.Info}
        </TableCell>
        <TableCell className={classes.col}>
          <Typography className={classes.title}>
            {t("table_options.mode")}
          </Typography>
         {checkMode(device.State?.Mode)}
        </TableCell>
        <TableCell className={classes.col}>
          <Typography className={classes.title}>
            {t("table_options.offline_t")}
          </Typography>
          {device.State?.FT}
        </TableCell>
        <TableCell className={classes.col}>
          <Typography className={classes.title}>
            {t("table_options.child_lock")}
          </Typography>
          {checkStatus(device.State?.Lock)}
        </TableCell>
        <TableCell className={classes.col}>
          <Typography className={classes.title}>
            {t("table_options.lights_status")}
          </Typography>
          {checkStatus(device.State?.Lights)}
        </TableCell>
        <TableCell className={classes.col}>
          <Typography className={classes.title}>
            {t("table_options.frost_protect")}
          </Typography>
          {checkStatus(device.State?.FTS)}
        </TableCell>
        <TableCell className={classes.col}>
          <Link className={classes.link} to={`/devices/${device.ID}`}>
            <img src={ArrowIcon} alt="" />
          </Link>
        </TableCell>
      </TableRow>
      {device.problem && (
        <TableRow className={classes.troubleshootingRow}>
          <TableCell align="left" colSpan={10}>
            <TroubleshootingMsg
              modal={true}
              type={device.problem?.level === "urgent" ? "red" : "gray"}
              message={device.problem?.message}
              openModal={handleOpenTroubleshooting}
            />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default SearchDeviceRow;
