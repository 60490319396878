import React from "react";

const Icon = (props) => {
  return (
    <svg
      width="76"
      height="20"
      viewBox="0 0 76 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.styleClass}
    >
      <path
        d="M4.91419 20C6.39625 20 7.28028 19.5957 7.98231 19.1914L7.41029 17.2776C6.81226 17.628 6.03223 17.9515 5.2782 17.9515C4.08216 17.9515 3.71814 17.1698 3.71814 15.6065V8.59838H7.93031V6.71159H3.71814V1.99461L1.61206 2.2372V6.71159H0V8.59838H1.61206V15.9299C1.61206 18.5714 2.6521 20 4.91419 20Z"
        fill={props.fill}
      />
      <path
        d="M17.6094 6.54987C15.6593 6.54987 13.9952 7.35849 12.9552 8.86792H12.9032V0H10.7971V19.8383H12.9032V13.2345C12.9032 10.2965 14.4893 8.57143 17.0114 8.57143C18.8574 8.57143 20.1055 9.62264 20.1055 12.5876V19.8383H22.2116V11.9677C22.2116 8.22102 20.3655 6.54987 17.6094 6.54987Z"
        fill={props.fill}
      />
      <path
        d="M37.1357 13.0728C37.1357 9.29919 34.7956 6.54987 31.2595 6.54987C27.8274 6.54987 25.2013 9.32615 25.2013 13.2884C25.2013 17.2776 27.9054 20 31.4935 20C33.8076 20 35.5237 19.0027 36.6157 17.5472L35.1336 16.2264C34.3536 17.2507 33.1576 18.0054 31.5715 18.0054C29.2834 18.0054 27.6194 16.4151 27.3333 13.9623H37.0837C37.0837 13.8814 37.1357 13.4232 37.1357 13.0728ZM31.3635 8.57143C33.3916 8.57143 34.7176 10 35.0556 12.1563H27.4114C27.8014 9.91914 29.3874 8.57143 31.3635 8.57143Z"
        fill={props.fill}
      />
      <path
        d="M55.3821 6.54987C53.4581 6.54987 51.794 7.46631 50.78 9.13747H50.7539C50.0779 7.38545 48.6739 6.54987 46.7238 6.54987C45.0857 6.54987 43.4997 7.4124 42.4596 8.97574H42.4336V6.71159H40.3535V19.8383H42.4336V13.3154C42.4336 10.3774 44.0457 8.57143 46.0998 8.57143C48.0758 8.57143 49.1159 9.81132 49.1159 12.7224V19.8383H51.222V13.3154C51.222 10.3774 52.808 8.57143 54.7581 8.57143C56.7602 8.57143 57.7222 9.78437 57.7222 12.7493V19.8383H59.8283V12.1563C59.8283 8.27493 58.2682 6.54987 55.3821 6.54987Z"
        fill={props.fill}
      />
      <path
        d="M69.3331 20C73.0512 20 75.8333 17.2776 75.8333 13.2884C75.8333 9.29919 73.0512 6.54987 69.3331 6.54987C65.3809 6.54987 62.7808 9.51482 62.7808 13.2884C62.7808 17.062 65.3809 20 69.3331 20ZM69.3331 18.0054C66.707 18.0054 64.9129 15.903 64.9129 13.2884C64.9129 10.6739 66.681 8.57143 69.3331 8.57143C71.7772 8.57143 73.7013 10.4852 73.7013 13.2884C73.7013 16.0647 71.7772 18.0054 69.3331 18.0054Z"
        fill={props.fill}
      />
    </svg>
  );
};
export default Icon;
