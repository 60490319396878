import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      position: "relative",
      padding: "18px 0 24px 0",
      "& .MuiTableCell-root": {
        padding: "18px 50px 24px 0",
        fontFamily: "Roboto",
        fontSize: "16px",
        lineHeight: "19px",
        border: "none",
        "&:last-of-type": {
          padding: "0",
        },
        "& svg": {
          margin: "0 10px 0 0",
        },
      },
      "&:first-of-type": {
        "&:after": {
          content: "none",
        },
      },
      "&:after": {
        content: "''",
        position: "absolute",
        top: "0",
        left: "50%",
        transform: "translate(-50%, 0)",
        background: "#D9DEE3",
        height: "1px",
        width: "100%",
      },
    },
    col: {},
    deviceName: {
      display: "flex",
      alignItems: "center",
      width: "244px",
      "& p": {
        maxWidth: "calc(100% - 24px)",
      },
    },
    customTitle: {
      color: Colors.dark,
      "& span": {
        fontWeight: 700,
      }
    },
    status: {
      fontWeight: 500,
    },
    title: {
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#B5BABF",
      margin: "0 0 10px 0",
    },
    link: {
      display: "block",
      width: "24px",
      padding: "16px 0 16px 0",
      marginLeft: "auto",
    },
    troubleshootingRow: {
      "& .MuiTableCell-root": {
        border: "none",
        padding: "0 0 24px 0",
      },
    },
  })
);
