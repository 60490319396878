import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import Button from "@material-ui/core/Button";
import LoginInput from "../LoginInput";
import { Typography } from "@material-ui/core";

interface iProps {
  email: string;
  emailError?: string;
  passwordError?: string;
  password: string;
  handleChange: (e: React.FormEvent<HTMLInputElement>, type: string) => void;
  handleLogin: () => void;
  handleKeyPress: (e: any) => void;
}

const LoginCard = (props: iProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    email, handleChange, emailError, passwordError, password, handleLogin, handleKeyPress,
  } = props;

  return (
    <div className={classes.loginBox}>
      <form className={classes.loginCard} onKeyPress={handleKeyPress}>
        <Typography component="h1" variant="h1" className={classes.title}>
          {t("login.login")}
        </Typography>

        <LoginInput
          value={email}
          name="email"
          type="email"
          placeholder={t("login.email")}
          handleChange={handleChange}
          autoFocus
          error={emailError}
          errorMsg={emailError || ""}
        />

        <LoginInput
          value={password}
          name="password"
          type="password"
          placeholder={t("login.password")}
          errorMsg={passwordError || ""}
          error={passwordError}
          handleChange={handleChange}
        />

        <Button
          color={"primary"}
          variant={"outlined"}
          onClick={handleLogin}
          className={classes.button}
          disabled={!email || !password}
        >
          {t("login.log_in")}
        </Button>
        <Typography className={classes.hint}>
          {t("login.hint")}

          <a href="mailto:support@themo.io">support@themo.io</a>
        </Typography>
      </form>
    </div>
  );
};

export default LoginCard;
