import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    langList: {
      display: "flex",
    },
    langLink: {
      cursor: "pointer",
      position: "relative",
      fontFamily: "Roboto",
      fontSize: "14px",
      lineHeight: "16px",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      padding: "6px",
      transition: "0.2s",
      "&.black": {
        color: "#414042",
        "&:hover": {
          color: Colors.brand,
        },
      },
      "&.white": {
        opacity: "0.4",
        color: "#fff",
        "&:hover": {
          opacity: "1",
        },
      },
    },
    activeLangLink: {
      opacity: "1",
      pointerEvents: "none",
      "&:after": {
        content: "\"\"",
        position: "absolute",
        bottom: "0",
        right: "6px",
        width: "calc(100% - 12px)",
        height: "1px",
      },
      "&.black": {
        color: Colors.brand,
        "&:after": {
          background: Colors.brand,
        },
      },
      "&.white": {
        color: "#fff",
        opacity: "1",
        "&:after": {
          background: "#fff",
        },
      },
    },
  }),
);
