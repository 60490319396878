import React from "react";
import { useStyles } from "./styles";
import Logo from "../../assets/icons/themoLogo";
import LoadingCircle from "../../assets/icons/loadingCircle.svg";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const LoginLoading = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.loginLoading}>
      <Logo styleClass={classes.logo} fill="#fff" />
      <Typography className={classes.title}>
        {t("login.energy_saving_service")}
      </Typography>
      <img className={classes.loadingCircle} src={LoadingCircle} alt="" />
    </div>
  );
};

export default LoginLoading;
