import { styled } from "@material-ui/core/styles";

const Row = styled("div")({
  position: "relative",
  display: "flex",
  width: "100%",
  overflow: "hidden",
  marginTop: 8,
  marginBottom: 2,
  paddingBottom: 6,
  // height: "56px",
  // border: '1px solid black',
  zIndex: 2,
});

export default Row;