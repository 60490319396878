import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useScrollbarSize from "react-scrollbar-size";
import clsx from "clsx";
import ProfileDropdown from "../ProfileDropdown";
import { useStyles } from "./styles";
import Logo from "../../assets/icons/themoLogo";
import SearchBar from "../SearchBar";
import { useTranslation } from "react-i18next";
import { useDevices } from "../../state/Devices";
import { Colors } from "../../constants/Style";
import { Typography } from "@material-ui/core";
import RestorePageIcon from '@material-ui/icons/RestorePage';

interface IProps {
  color: "primary" | "secondary";
}

const Header = (props: IProps) => {
  const { color } = props;
  const classes = useStyles();
  const [fixed, setFixed] = useState<boolean>(false);
  const { t } = useTranslation();
  const { width } = useScrollbarSize();

  useEffect(() => {
    window.onscroll = () => {
      window.pageYOffset === 0 ? setFixed(false) : setFixed(true);
    };
  }, []);

  const { hardRefreshDevicesData, loading } = useDevices();

  return (
    <header
      style={{ paddingRight: width }}
      className={clsx(
        classes.header,
        color === "primary" ? classes.transparent : classes.white,
        fixed && classes.headerFixed,
      )}
    >
      <div className={classes.wrap}>
        <div className={classes.col}>
          <Link
            className={clsx(classes.logo, {
              [classes.logoDisabled]: loading
            })}
            to="/"
            onDoubleClick={hardRefreshDevicesData}
          >
            <Typography
              component={"span"} variant={"body2"}
              className={clsx(classes.logoTooltip, {
                [classes.logoTooltipPrimary]: color === "primary"
              })}>
              <RestorePageIcon/>
              {"Double click for hard reload"}
            </Typography>
            <Logo fill={fixed || color === "secondary" ? Colors.brand : Colors.white} />
          </Link>

          <SearchBar />

        </div>
        <div className={classes.col}>
          <Link
            className={clsx(
              classes.link,
              (fixed || color === "secondary") && classes.linkBlack,
            )}
            to="/"
          >
            <span>{t("pages.dashboard")}</span>
          </Link>
          <Link
            className={clsx(
              classes.link,
              (fixed || color === "secondary") && classes.linkBlack,
            )}
            to="/groups"
          >
            <span>{t("pages.groups")}</span>
          </Link>
          <Link
            className={clsx(
              classes.link,
              (fixed || color === "secondary") && classes.linkBlack,
            )}
            to="/buildings"
          >
            <span>{t("pages.buildings")}</span>
          </Link>
          <Link
            className={clsx(
              classes.link,
              (fixed || color === "secondary") && classes.linkBlack,
            )}
            to="/devices"
          >
            <span>{t("pages.devices")}</span>
          </Link>

          <ProfileDropdown fixed={fixed || color === "secondary"} />
        </div>
      </div>
    </header>
  );
};

export default Header;
