import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    zIndex: 9999,
    top: 0,
    right: 0,
    width: "100%",
    // backgroundColor: Colors.brand,
  },
}));

const AppLinearProgress = () => {
  const classes = useStyles();

  return (
    <LinearProgress className={classes.root} />
  );
};

export default AppLinearProgress;