import React, { Suspense, lazy } from "react";
import Loader from "../../components/Loader/Loader";
import { Route, Switch } from "react-router-dom";

const Dashboard = lazy(() => import("../../views/Dashboard/Dashboard"));
const Buildings = lazy(() => import("../../views/Buildings"));
const BuildingDevices = lazy(() => import("../../views/BuildingDevices"));
const EnergyProfile = lazy(() => import("../../views/EnergyProfile"));
const Consumtion = lazy(() => import("../../views/Consumtion"));
const Devices = lazy(() => import("../../views/Devices"));
const Device = lazy(() => import("../../views/Device"));
const SearchResult = lazy(() => import("../../views/Search"));

export default function Routes() {

  return (
    <Suspense fallback={<Loader width={"100%"} />}>
      <Switch>
        <Route path={"/"} exact component={Dashboard} />
        <Route
          path={"/groups"}
          exact
          render={() => <Buildings isGroups={true} />}
        />
        <Route
          path={"/groups/:groupName/devices"}
          render={() => <BuildingDevices isGroups={true} />}
        />
        <Route path={"/buildings"} exact component={Buildings} />
        <Route
          path={"/buildings/:buildingName/devices"}
          component={BuildingDevices}
        />
        <Route
          path={"/buildings/:buildingName/energy-profile"}
          component={EnergyProfile}
        />
        <Route
          path={"/buildings/:buildingName/consumtion"}
          component={Consumtion}
        />
        <Route path={"/devices"} exact component={Devices} />
        <Route path={"/devices/:deviceId"} component={Device} />
        <Route path={"/search"} exact={false} component={SearchResult} />
      </Switch>
    </Suspense>
  );
}