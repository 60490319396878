import React  from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { useTranslation } from "react-i18next";
// import TroubleshootingModal from "../TroubleshootingModal";
import ErrorIcon from "../../assets/icons/ErrorIcon";
import { Typography } from "@material-ui/core";

interface IProps {
  modal: boolean;
  type: "red" | "gray";
  message?: string | JSX.Element;
  openModal?: () => void;
  buttonText?: string;
}

const TroubleshootingMsg = (props: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    modal,
    type,
    message = "default message (to remove)",
    openModal = () => null,
    buttonText = t("troubleshooting.troubleshooting"),
  } = props;

  return (
    <>
      <div
        className={clsx(
          classes.troubleshootingMsg,
          type === "red" ? classes.red : classes.gray
        )}
      >
        <ErrorIcon fill={type === "red" ? "#F7204F" : "#85868B"} />
        <Typography variant="body2" component="div">
          {message}
          {modal && (
            <Typography component="span" variant="body2" onClick={openModal}>
              {buttonText}
            </Typography>
          )}
        </Typography>
      </div>
    </>
  );
};

export default TroubleshootingMsg;
