import React, { useState, useRef, useCallback } from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import ChangeLang from "../ChangeLang";
import { useAuth } from "../../state/Auth";
import UserIcon from "../../assets/icons/User";
import LogOut from "../../assets/icons/logOut.svg";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useTranslation } from "react-i18next";

interface IProps {
  fixed?: boolean;
}

const Header = (props: IProps) => {
  const classes = useStyles();
  const { fixed } = props;
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef(null);
  const { logout } = useAuth();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = useCallback(() => {
    logout && logout();
  }, [logout]);

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.button}
      >
        <UserIcon styleClass={clsx(classes.icon, fixed && classes.fixedIcon)} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-end"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper>
              <ClickAwayListener onClickAway={handleToggle}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <MenuItem className={classes.menuItem}>
                    {t("langs.language")}
                    : <ChangeLang color="black" />
                  </MenuItem>
                  <MenuItem className={classes.menuItem}>
                    {t("login.log_out")}
                    <button onClick={handleLogout}>
                      <img src={LogOut} alt="" />
                    </button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default Header;
