import React from "react";

const Icon = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4 4l16 16M20 4L4 20" stroke="#D9DEE3" />
    </svg>
  )
};
export default Icon;
