import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { UseAuth, userType } from "../types/Auth";
import { mutateData } from "../api/api";
import { Endpoints } from "../api/endpoints";
import queryString from "querystring";
import Cookies from "js-cookie";
import { languages } from "../constants/languages";
import { useLang } from "./Lang";


const initialAuthValues = {
  isLoggedIn: false,
  isLoading: false,
};

const authContext = createContext(initialAuthValues);

const useProvideAuth = (): UseAuth => {
  const [token, setToken] = useState<string | undefined>(
    Cookies.get("_token"),
  );

  const storedUser = Cookies.get("themo-user");

  const [user, setUser] = useState<userType | undefined>(JSON.parse(storedUser || "{}"));

  const expiresIn = useMemo(() => Number(Cookies.get("expires_in")), []);

  const seconds = new Date().getTime() / 1000;

  const [userId, setUserId] = useState<number>(
    Number(Cookies.get("userId")),
  );
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!token && (expiresIn ? expiresIn > seconds : true));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { lang, changeLang } = useLang();

  const getAccount = useCallback(async (token: string) => {
    await mutateData(
      "get",
      `${Endpoints.getAccount}`,
      {},
      {
        "Content-Type": "application/json",
        "client-id": "ThemoPortal",
        Authorization: `Bearer ${token}`,
      },
    ).then((r) => {
      console.log("r", r);
      const { data } = r;
      const { ID, CountryShort } = data;
      setUserId(ID);
      Cookies.set("userId", ID);
      const _user: userType = {
        ...data,
      };
      setUser(_user);
      Cookies.set("themo-user", _user);

      if (CountryShort) {
        let lng = languages.find(l => l.key === CountryShort.toLowerCase());
        if (lng && lng.key !== lang) {
          changeLang(lng.key);
        }
      }
    });
  }, [lang, changeLang]);

  const login = useCallback(
    (email: string, password: string) => {
      setIsLoading(true);
      return mutateData(
        "post",
        `${Endpoints.login}`,
        queryString.stringify({
          username: email,
          password: password,
          grant_type: "password",
        }),
        {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      )
        .then((r) => {
          console.log("r", r);
          if (r && r.status === 200) {
            const { data } = r;
            const { access_token, expires_in } = data;

            Cookies.set("_token", access_token);
            Cookies.set("expires_in", expires_in);
            setToken(access_token);

            getAccount(access_token).then(() => {
              console.log("Was get account");
              setIsLoggedIn(true);
              if (!expiresIn) {
                console.log("was first time login");
                setTimeout(() => {
                  setIsLoading(false);
                }, 2000);
              } else {
                setIsLoading(false);
              }
            });
          } else {
            setIsLoading(false);
          }
          return {
            status: true
          }
        })
        .catch(() => {
          setIsLoading(false);
          return {
            status: false
          }
        });
    },
    [expiresIn, getAccount],
  );

  const logout = useCallback(() => {
    sessionStorage.removeItem("themo-devices");
    Cookies.remove("_token");
    Cookies.remove("themo-user");
    // Cookies.remove("expires_in");
    setToken(undefined);
    setUser(undefined);
    setIsLoggedIn(false);
  }, []);

  return {
    isLoggedIn,
    isLoading,
    login,
    logout,
    userId,
    token,
    user,
  };
};

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = (): UseAuth => {
  return useContext(authContext);
};
