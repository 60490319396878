import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseLang } from "../types/Lang";

const initialLangValues = {
  lang: "en",
  changeLang: () => null,
};

const langContext = createContext<UseLang>(initialLangValues);

const useProvideLang = (): UseLang => {
  const [lang, setLang] = useState<string>(localStorage.getItem("themo-lang") || "en");
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.language && setLang(i18n.language);
  }, [i18n]);

  const changeLang = useCallback(
    (lng: string) => {
      if (lng !== lang) {
        i18n.changeLanguage(lng).catch(console.log);
        setLang(lng);
        localStorage.setItem("themo-lang", lng);
      }
    },
    [i18n, lang],
  );

  return {
    lang,
    changeLang,
  };
};

export const LangProvider = ({ children }: PropsWithChildren<{}>) => {
  const langProvide = useProvideLang();
  return <langContext.Provider value={langProvide}>{children}</langContext.Provider>;
};

export const useLang = (): UseLang => {
  return useContext(langContext);
};